// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Recent.css */

/* Container Styling */
.recent-container {
    padding: 20px;
  }
  
  /* Cards Styling */
  .cards-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .card {
    background-color: #28a745;
    color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    flex: 1 1;
    margin-right: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .card:last-child {
    margin-right: 0;
  }
  
  /* Table Styling */
  .table-container {
    margin-top: 20px;
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  }
  
  td {
    color: #333;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Recent.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B,sBAAsB;AACtB;IACI,aAAa;EACf;;EAEA,kBAAkB;EAClB;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,SAAO;IACP,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,eAAe;EACjB;;EAEA,kBAAkB;EAClB;IACE,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,6BAA6B;EAC/B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;EACb","sourcesContent":["/* src/components/Recent.css */\n\n/* Container Styling */\n.recent-container {\n    padding: 20px;\n  }\n  \n  /* Cards Styling */\n  .cards-container {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 20px;\n  }\n  \n  .card {\n    background-color: #28a745;\n    color: #ffffff;\n    padding: 15px;\n    border-radius: 8px;\n    text-align: center;\n    flex: 1;\n    margin-right: 10px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  }\n  \n  .card:last-child {\n    margin-right: 0;\n  }\n  \n  /* Table Styling */\n  .table-container {\n    margin-top: 20px;\n    overflow-x: auto;\n  }\n  \n  table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  th, td {\n    padding: 12px;\n    text-align: left;\n    border-bottom: 1px solid #ddd;\n  }\n  \n  th {\n    background-color: #f4f4f4;\n  }\n  \n  tr:hover {\n    background-color: #f1f1f1;\n  }\n  \n  td {\n    color: #333;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
