// src/components/Recent.js
import React from 'react';
import './Recent.css'; // Custom CSS for Recent component

function Recent() {
  return (
    <div className="recent-container">
      {/* Card Layout */}
      <div className="cards-container">
        <div className="card">Total Exercises: 15</div>
        <div className="card">Completed: 10</div>
        <div className="card">In Progress: 3</div>
        <div className="card">Issues Reported: 2</div>
      </div>

      {/* Horizontal Line */}
      <hr />

      {/* Table Layout */}
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Exercise ID</th>
              <th>Instructor Name</th>
              <th>Observer Name</th>
              <th>Track Name</th>
              <th>Start Location</th>
              <th>End Location</th>
              <th>Distance</th>
              <th>Covered</th>
              <th>Environment</th>
              <th>Malfunctions</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>EX12345</td>
              <td>John Doe</td>
              <td>Mary Smith</td>
              <td>Advanced Track</td>
              <td>New York</td>
              <td>Boston</td>
              <td>300 miles</td>
              <td>250 miles</td>
              <td>Outdoor</td>
              <td>None</td>
              <td>In Progress</td>
            </tr>
            <tr>
              <td>EX12346</td>
              <td>Jane Smith</td>
              <td>Robert Brown</td>
              <td>Basic Track</td>
              <td>Los Angeles</td>
              <td>San Diego</td>
              <td>150 miles</td>
              <td>150 miles</td>
              <td>Indoor</td>
              <td>Engine failure</td>
              <td>Completed</td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Recent;
