// src/components/Instructors.js
import React from 'react';
import './Instructors.css'; // Custom CSS for Instructors component

function Instructors() {
  return (
    <div className="instructors-container">
      {/* Card Layout */}
      <div className="cards-container">
        <div className="card">Total Instructor Assigned: 10</div>
        <div className="card">Total Approved: 6</div>
        <div className="card">Total Rejected: 2</div>
        <div className="card">Total Feedback: 2</div>
      </div>

      {/* Horizontal Line */}
      <hr />

      {/* Table Layout */}
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Location</th>
              <th>Track</th>
              <th>Available Slot</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>John Doe</td>
              <td>New York</td>
              <td>Frontend</td>
              <td>9:00 AM - 12:00 PM</td>
              <td><button className="request-button">Request</button></td>
            </tr>
            <tr>
              <td>Jane Smith</td>
              <td>Los Angeles</td>
              <td>Backend</td>
              <td>1:00 PM - 4:00 PM</td>
              <td><button className="request-button">Request</button></td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Instructors;
