// src/components/Recent.js
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './Current.css'; // Custom CSS for Current component
import { WeatherContext } from '../contexts/WeatherContext';

function Current() {
  const navigate = useNavigate();
  const { setWeatherMode } = useContext(WeatherContext);

  const handleStartExercise = (exerciseId, weatherMode) => {
    // Navigate to ExerciseDetail with exerciseId and weatherMode as state
    navigate(`/exercise/${exerciseId}`, { state: { weatherMode } });
    setWeatherMode(() => weatherMode);
  };

  const handleStartOutdoorExercise = (exerciseId, weatherMode) => {
    // Navigate to ExerciseDetail with exerciseId and weatherMode as state
    navigate(`/outdoorexercise/${exerciseId}`, { state: { weatherMode } });
    setWeatherMode(() => weatherMode);
  };

  return (
    <div className="current-container">
      {/* Card Layout */}
      <div className="cards-container">
        <div className="card">Current Availabe Exercises: 3</div>
        <div className="card">Completed: 1</div>
        <div className="card">In Progress: 2</div>
        <div className="card">Issues Reported: 1</div>
      </div>

      {/* Horizontal Line */}
      <hr />

      {/* Table Layout */}
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Exercise ID</th>
              <th>Instructor Name</th>
              <th>Observer Name</th>
              <th>Track Name</th>
              <th>Start Location</th>
              <th>End Location</th>
              <th>Distance</th>
              <th>Covered</th>
              <th>Environment</th>
              <th>Malfunctions</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>EX12345</td>
              <td>John Doe</td>
              <td>Mary Smith</td>
              <td>Advanced Track</td>
              <td>New York</td>
              <td>Boston</td>
              <td>300 miles</td>
              <td>250 miles</td>
              <td>Sunny</td>
              <td>None</td>
              <td>In Progress</td>
              <td><button className="start-button" onClick={() => handleStartExercise('EX12345', "sunny")}>Start Exercise</button></td>
            </tr>
            <tr>
              <td>EX12345</td>
              <td>John Doe</td>
              <td>Mary Smith</td>
              <td>Advanced Track</td>
              <td>New York</td>
              <td>Boston</td>
              <td>300 miles</td>
              <td>250 miles</td>
              <td>Rainy</td>
              <td>None</td>
              <td>In Progress</td>
              <td><button className="start-button" onClick={() => handleStartExercise('EX12345', "rainy")}>Start Exercise</button></td>
            </tr>
            <tr>
              <td>EX12345</td>
              <td>John Doe</td>
              <td>Mary Smith</td>
              <td>Advanced Track</td>
              <td>New York</td>
              <td>Boston</td>
              <td>300 miles</td>
              <td>250 miles</td>
              <td>Night</td>
              <td>None</td>
              <td>In Progress</td>
              <td><button className="start-button" onClick={() => handleStartExercise('EX12345', "night")}>Start Exercise</button></td>
            </tr>
            <tr>
              <td>EX12345</td>
              <td>John Doe</td>
              <td>Mary Smith</td>
              <td>Advanced Track</td>
              <td>New York</td>
              <td>Boston</td>
              <td>300 miles</td>
              <td>250 miles</td>
              <td>Foggy</td>
              <td>None</td>
              <td>In Progress</td>
              <td><button className="start-button" onClick={() => handleStartExercise('EX12345', "foggy")}>Start Exercise</button></td>
            </tr>
            <tr>
              <td>EX12345</td>
              <td>John Doe</td>
              <td>Mary Smith</td>
              <td>Advanced Track</td>
              <td>New York</td>
              <td>Boston</td>
              <td>300 miles</td>
              <td>250 miles</td>
              <td>Outdoor-Sunny</td>
              <td>None</td>
              <td>In Progress</td>
              <td><button className="start-button" onClick={() => handleStartOutdoorExercise('EX12345', "sunny")}>Start Exercise</button></td>
            </tr>
            <tr>
              <td>EX12345</td>
              <td>John Doe</td>
              <td>Mary Smith</td>
              <td>Advanced Track</td>
              <td>New York</td>
              <td>Boston</td>
              <td>300 miles</td>
              <td>250 miles</td>
              <td>Outdoor-Rainy</td>
              <td>None</td>
              <td>In Progress</td>
              <td><button className="start-button" onClick={() => handleStartOutdoorExercise('EX12345', "rainy")}>Start Exercise</button></td>
            </tr>
            <tr>
              <td>EX12345</td>
              <td>John Doe</td>
              <td>Mary Smith</td>
              <td>Advanced Track</td>
              <td>New York</td>
              <td>Boston</td>
              <td>300 miles</td>
              <td>250 miles</td>
              <td>Outdoor-Night</td>
              <td>None</td>
              <td>In Progress</td>
              <td><button className="start-button" onClick={() => handleStartOutdoorExercise('EX12345', "night")}>Start Exercise</button></td>
            </tr>
            <tr>
              <td>EX12345</td>
              <td>John Doe</td>
              <td>Mary Smith</td>
              <td>Advanced Track</td>
              <td>New York</td>
              <td>Boston</td>
              <td>300 miles</td>
              <td>250 miles</td>
              <td>Outdoor-Foggy</td>
              <td>None</td>
              <td>In Progress</td>
              <td><button className="start-button" onClick={() => handleStartOutdoorExercise('EX12345', "foggy")}>Start Exercise</button></td>
            </tr>
            <tr>
              <td>EX12346</td>
              <td>Jane Smith</td>
              <td>Robert Brown</td>
              <td>Basic Track</td>
              <td>Los Angeles</td>
              <td>San Diego</td>
              <td>150 miles</td>
              <td>150 miles</td>
              <td>Indoor</td>
              <td>Engine failure</td>
              <td>Completed</td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Current;
