import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

// Create a context
const SocketContext = createContext();

// Initialize a single instance of the socket connection
const socket = io('https://control-software.aulas.in', {
  transports: ['websocket'],
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
});

// Provider component to wrap the app and provide the socket connection
export const SocketProvider = ({ children }) => {
    const [connectionStatus, setConnectionStatus] = useState('Disconnected');
    const [error, setError] = useState(null);

  useEffect(() => {
    socket.on('connect', () => {
        setConnectionStatus('Connected');
        console.log('Connected to server');
        setError(null);
      });
  
      socket.on('disconnect', (reason) => {
        setConnectionStatus('Disconnected');
        console.log(`Disconnected: ${reason}`);
      });
  
      socket.on('reconnect_attempt', (attemptNumber) => {
        setConnectionStatus(`Reconnecting... (Attempt ${attemptNumber})`);
        console.log(`Reconnection attempt #${attemptNumber}`);
      });
  
      socket.on('reconnect_failed', () => {
        setConnectionStatus('Reconnection Failed');
        setError('Reconnection failed. Please check your network or server status.');
        console.error('Reconnection failed.');
      });
  
      socket.on('connect_error', (err) => {
        setConnectionStatus('Connection Error');
        setError(`Connection error: ${err.message}`);
        console.error('Connection error:', err);
      });

      // Clean up event listeners when the component unmounts
    return () => {
        socket.off('connect');
        socket.off('disconnect');
        socket.off('reconnect_attempt');
        socket.off('reconnect_failed');
        socket.off('connect_error');
      };

  }, []);

  return (
    <SocketContext.Provider value={{ socket, connectionStatus, error }}>
      {children}
    </SocketContext.Provider>
  );
};

// Custom hook to use the socket instance in other components
export const useSocket = () => {
  return useContext(SocketContext);
};
