/* eslint-disable */
import React, { useEffect, useRef, useContext} from 'react';
import styled from 'styled-components';
import { useParams, useLocation } from 'react-router-dom';
import * as THREE from 'three';
import { OrbitControls } from './OrbitControls';
import io from 'socket.io-client';
import { OBJLoader } from './OBJLoader.js'; // Ensure this file is available
import { WeatherContext } from '../contexts/WeatherContext.js';
import { useSocket } from '../contexts/SocketContext';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%; // Make sure it takes the full width
    margin: 0; // Remove default margins
    padding: 0; // Remove default padding
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;

    @media (max-width: 768px) {
        padding: 8px;
        gap: 8px;
    }
`;

const OutdoorExerciseDetail = () => {
    const { socket, connectionStatus, error } = useSocket(); // Access the context
    const mountRef = useRef(null);
    const animationRef = useRef(null);
    const sceneInitialized = useRef(false); // Flag to ensure scene is initialized only once
    const keyPressedRef = useRef({ up: false, down: false }); // Ref to store key states
    let scene, camera, renderer, controls;
    let cameraSpeed = 0.005;
    let sunLight, moonLight,streetLight,directionalLight,ambientLight;
    let hornSound, engineSound;
    let angle = Math.PI / 2;
    let keyPressed = { up: false, down: false };
    let currentSpeed = 0;
    let maxSpeed = 300;
    let acceleration = 0.01;
    let deceleration = 0.002;
    let brakingSpeed = 0.01;
    let lastSentTime = 0;
    const sendInterval = 2000;
    let rainParticles = [];

    // Ground setup with crops
    const groundWidth = 100;
    const groundDepth = 100;
    const groundGeometry = new THREE.PlaneGeometry(groundWidth, groundDepth);
    const groundMaterial = new THREE.MeshStandardMaterial({ color: 0x228b22 });
    const grounds = [];
    const cropGroups = [];
    const trackWidth = 8;
    const tracks = [];
    let train;

    const objLoader = new OBJLoader();
    let cropModel;

    const { exerciseId } = useParams(); // Retrieve exerciseId from the URL
    const { weatherMode } = useContext(WeatherContext);
    // const location = useLocation(); // Retrieve weatherMode from state
    // const { weatherMode } = location.state || {};

    useEffect(() => {
        if(!sceneInitialized.current) {
            sceneInitialized.current = true; // Mark as initialized
            const loadingManager = new THREE.LoadingManager();
            loadingManager.onStart = () => console.log('Loading started');
            loadingManager.onLoad = () => {
                console.log('All assets loaded');
                animate(); // Start animation only after all assets are loaded
            };
            loadingManager.onError = (url) => console.error('Error loading:', url);
            init(loadingManager);

            window.addEventListener('keydown', onDocumentKeyDown);
            // window.addEventListener('keyup', onDocumentKeyUp);
            if (weatherMode) {
                setWeatherMode(weatherMode);
            }
            console.log(weatherMode , "weatherMode");
            

        return () => {
            cancelAnimationFrame(animationRef.current);
            // window.removeEventListener('keydown', handleKeyDown, true);// only this works
            // window.removeEventListener('keyup', handleKeyUp, true);
            window.removeEventListener('resize', onWindowResize);
            window.removeEventListener('keydown', onDocumentKeyDown);// does not work
            // window.removeEventListener('keyup', onDocumentKeyUp);
            // socket.disconnect();
        };
        }
        

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weatherMode]);

        const init = (loadingManager) => {
            scene = new THREE.Scene();
            scene.background = new THREE.Color(0xa0d3f8);

            // Camera setup
            camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
            camera.position.set(0, 3.5, 9);

            renderer = new THREE.WebGLRenderer();
            renderer.setSize(window.innerWidth, window.innerHeight);
            renderer.shadowMap.enabled = true;
            mountRef.current.appendChild(renderer.domElement);

            controls = new OrbitControls(camera, renderer.domElement);
            controls.enableDamping = true;
            controls.enabled = false;

            // Sunlight
            sunLight = new THREE.DirectionalLight(0xffffff, 1);
            sunLight.position.set(10, 2, 10);
            sunLight.castShadow = true;
            sunLight.shadow.mapSize.width = 1024;
            sunLight.shadow.mapSize.height = 1024;
            // scene.add(sunLight);

            moonLight = new THREE.PointLight(0x8888ff, 0.5, 100);
            moonLight.position.set(-20, 30, -20);
            scene.add(moonLight);

            ambientLight = new THREE.AmbientLight(0xffffff, 0);
            scene.add(ambientLight);

            directionalLight = new THREE.DirectionalLight(0xffffff, 0.4);
            directionalLight.position.set(5, 10, 5);
            scene.add(directionalLight);

            

            // Load the .obj model for crops
            objLoader.load('../Tree1.obj', (object) => {
                cropModel = object;
                cropModel.scale.set(0.5, 0.5, 0.5); // Adjust scale as needed
                initializeScene(); // Initialize the scene after the model is loaded
            });
            // createCity(loadingManager);
            // createImprovedTrainTrack();
            hornSound = loadAudio('/train-horn.mp3', camera); // Load train horn sound
            engineSound = loadAudio('/engine-sound.mp3', camera); // Load engine sound
            animate();
        };

        const createGroundAndCrops = (zPosition) => {
            // Create ground
            const groundGeometry = new THREE.PlaneGeometry(groundWidth, groundDepth);
            const groundMaterial = new THREE.MeshStandardMaterial({ color: 0x228b22 });
            const ground = new THREE.Mesh(groundGeometry, groundMaterial);
            ground.rotation.x = -Math.PI / 2;
            ground.position.z = zPosition;
            scene.add(ground);
            grounds.push(ground);

            // Create crops group if the crop model is loaded
            if (cropModel) {
                const cropGroup = new THREE.Group();

                // Increase spacing between crops for fewer rendered crops (e.g., 20 units)
                for (let x = -groundWidth / 2; x <= groundWidth / 2; x += 20) {
                    for (let z = -groundDepth / 2; z <= groundDepth / 2; z += 20) {
                        // Skip placing crops on the track area
                        if (Math.abs(x) > trackWidth / 2) {
                            const cropClone = cropModel.clone();
                            cropClone.position.set(x, 0.3, z + zPosition); // Lower the crops' height
                            cropGroup.add(cropClone);
                        }
                    }
                }
                cropGroup.position.z = zPosition;
                scene.add(cropGroup);
                cropGroups.push(cropGroup);
            }
        }

        const createTrack = (zPosition) => {
            // Create train tracks using basic geometry
            const railMaterial = new THREE.MeshStandardMaterial({ color: 0x8b4513 });
            const sleeperMaterial = new THREE.MeshStandardMaterial({ color: 0x555555 });
            const trackGroup = new THREE.Group();
        
            // Create rails
            const railGeometry = new THREE.BoxGeometry(0.2, 0.1, groundDepth);
            const leftRail = new THREE.Mesh(railGeometry, railMaterial);
            const rightRail = new THREE.Mesh(railGeometry, railMaterial);
            leftRail.position.set(-trackWidth / 2, 1.2, zPosition); // Raise the track height slightly
            rightRail.position.set(trackWidth / 2, 1.2, zPosition); // Raise the track height slightly
            trackGroup.add(leftRail, rightRail);
        
            // Create sleepers
            const sleeperGeometry = new THREE.BoxGeometry(trackWidth, 0.1, 1);
            for (let z = -groundDepth / 2; z <= groundDepth / 2; z += 5) {
                const sleeper = new THREE.Mesh(sleeperGeometry, sleeperMaterial);
                sleeper.position.set(0, 1, z + zPosition); // Raise the sleepers' height slightly
                trackGroup.add(sleeper);
            }
        
            trackGroup.position.z = zPosition;
            scene.add(trackGroup);
            tracks.push(trackGroup);
        }

        // //  Train setup
        //  const trainGeometry = new THREE.BoxGeometry(1, 1, 3);
        //  const trainMaterial = new THREE.MeshStandardMaterial({ color: 0xff0000 });
        //  const train = new THREE.Mesh(trainGeometry, trainMaterial);
        //  train.position.set(0, 1, 0);
        //  scene.add(train);
        //  currentSpeed = 0.1;


         const  initializeScene = () => {
            // Create initial grounds, crops, and tracks
            createGroundAndCrops(0);
            createGroundAndCrops(-groundDepth);
            createTrack(0);
            createTrack(-groundDepth);
        
            // Train setup - matching the track width
            const trainWidth = trackWidth; // Make the train width the same as the track width
            const trainGeometry = new THREE.BoxGeometry(trainWidth, 1.5, 5); // Adjusted dimensions for larger size
            const trainMaterial = new THREE.MeshStandardMaterial({ color: 0xff0000 });
            train = new THREE.Mesh(trainGeometry, trainMaterial);
            train.position.set(0, 1.75, 0); // Center the train and adjust height
            scene.add(train);
        
            // // Animation and infinite ground logic
            // window.addEventListener('keydown', (event) => {
            //     if (event.key === 'ArrowUp') {
            //         currentSpeed += 0.05;
            //     } else if (event.key === 'ArrowDown') {
            //         currentSpeed = Math.max(0.05, speed - 0.05);
            //     }
            // });
        
            animate();
            // Handle window resize
            window.addEventListener('resize', () => {
                renderer.setSize(window.innerWidth, window.innerHeight);
                camera.aspect = window.innerWidth / window.innerHeight;
                camera.updateProjectionMatrix();
            });
        }

        const  animate = () => {
            requestAnimationFrame(animate);
            if(train){
                train.position.z -= currentSpeed;
                camera.position.z = train.position.z + 10; // Camera follows the train
        
                // Move grounds, crops, and tracks to simulate infinite duplication
                grounds.forEach((ground, index) => {
                    if (train.position.z - ground.position.z < -groundDepth / 2) {
                        ground.position.z -= groundDepth * grounds.length;
                        cropGroups[index].position.z -= groundDepth * grounds.length;
                        tracks[index].position.z -= groundDepth * grounds.length;
                    }
                });
            }
            
            if (keyPressed.up) {
                // console.log("currentspeed acceleration", currentSpeed, acceleration);
                currentSpeed = Math.min(currentSpeed + acceleration, maxSpeed);
                if(currentSpeed < 50){
                    acceleration *= 1.002;
                    brakingSpeed /= 1.002;
                } else if(currentSpeed < 100){
                    acceleration *= 1.0001;
                    brakingSpeed /= 1.0001;
                }else if(currentSpeed < 150){
                    acceleration *= 1.00005;
                    brakingSpeed /= 1.00005;
                }else if(currentSpeed < 200){
                    acceleration *= 1.00005;
                    brakingSpeed /= 1.00005;
                }else{
                    acceleration *= 1.0000025;
                    brakingSpeed /= 1.0000025;
                }
                // keyPressed.up = false;
            } else if (keyPressed.down) {
                console.log("braking speed", brakingSpeed);
                // brakingSpeed += 0.5;
                
                currentSpeed = Math.max(currentSpeed - brakingSpeed, 0);
                if(currentSpeed > 200){
                    acceleration /= 1.02;
                    brakingSpeed *= 1.02;

                } else if(currentSpeed > 150){
                    acceleration /= 1.001;
                    brakingSpeed *= 1.001;
                }else if(currentSpeed > 100){
                    acceleration /= 1.0005;
                    brakingSpeed *= 1.0005;
                }else if(currentSpeed > 50){
                    acceleration /= 1.000025;
                    brakingSpeed *= 1.000025;
                }else{
                    acceleration /= 1.0000025;
                    brakingSpeed *= 1.0000025;
                }
                
                keyPressed.down = false; // not pressing continuously
            } 
            // Send data through Socket.io at 2-second intervals
            const currentTime = Date.now();
            if (currentTime - lastSentTime >= sendInterval) {
                lastSentTime = currentTime;
                if (socket.connected) {
                    const data = {
                        currentSpeed: parseInt(currentSpeed),
                        cameraSpeed,
                        angle,
                        maxSpeed,
                        throttle: parseInt(acceleration*1000),
                        deceleration,
                        brakePressure: parseInt(brakingSpeed*100),
                        keyPressed
                    };
                    socket.emit('updateData', data);
                    console.log('Data sent:', data);
                } else {
                    console.log("socket not connected");
                    
                }
            } else {
                console.log("time less than 2 sec");
                
            }
    
            renderer.render(scene, camera);
        }

        const createCity = (loadingManager) => {
            const textureLoader = new THREE.TextureLoader(loadingManager);
            const buildingTexture = textureLoader.load('/building_texture2.png');// it should be in public folder
            const buildingGeometry = new THREE.BoxGeometry(1, 3, 1);
            const buildingMaterial = new THREE.MeshStandardMaterial({ map: buildingTexture });

            for (let i = -10; i <= 10; i += 2) {
                for (let j = -10; j <= 10; j += 2) {
                    if (Math.random() > 0.2) {
                        const building = new THREE.Mesh(buildingGeometry, buildingMaterial);
                        building.position.set(i, 1.5, j);
                        building.scale.y = Math.random() * 3 + 1;
                        scene.add(building);
                    }
                }
            }
        };

        const createImprovedTrainTrack = () => {
            const railMaterial = new THREE.MeshStandardMaterial({ color: 0x333333, metalness: 0.7, roughness: 0.5 });
            const railWidth = 0.05;

            for (let i = -1; i <= 1; i += 2) {
                const railGeometry = new THREE.TorusGeometry(10 - (0.3 * i), railWidth, 16, 100);
                const rail = new THREE.Mesh(railGeometry, railMaterial);
                rail.rotation.x = Math.PI / 2;
                scene.add(rail);
            }

            const sleeperMaterial = new THREE.MeshStandardMaterial({ color: 0x8B4513 });
            for (let angle = 0; angle < Math.PI * 2; angle += 0.2) {
                const sleeperGeometry = new THREE.BoxGeometry(0.8, 0.02, 0.1);
                const sleeper = new THREE.Mesh(sleeperGeometry, sleeperMaterial);
                sleeper.position.x = 10 * Math.cos(angle);
                sleeper.position.z = 10 * Math.sin(angle);
                sleeper.position.y = 0.05;
                sleeper.rotation.y = angle + Math.PI / 2;
                scene.add(sleeper);
            }
        };

        const createMoon = () => {
            const moonGeometry = new THREE.SphereGeometry(1, 32, 32);
            const moonMaterial = new THREE.MeshStandardMaterial({ color: 0x8888ff });
            const moon = new THREE.Mesh(moonGeometry, moonMaterial);
            moon.position.set(-20, 30, -20);
            scene.add(moon);
        }

        const createStreetLightsAlongTrack = () => {
            console.log("adding streetlight");
            
            const streetLightColor = 0xffcc88;
            const lightIntensity = 1; // Adjust as needed for better visibility
            const lightDistance = 10;
            for (let angle = 0; angle < Math.PI * 2; angle += 0.5) {
                const lightPositionX = 10 * Math.cos(angle);
                const lightPositionZ = 10 * Math.sin(angle);
                streetLight = new THREE.PointLight(streetLightColor, lightIntensity, lightDistance);
                streetLight.position.set(lightPositionX, 0.5, lightPositionZ); // Adjust height as needed
                streetLight.castShadow = true; // Enable shadow casting
                // Create a small sphere to represent the streetlight visually
                const sphereGeometry = new THREE.SphereGeometry(0.1, 16, 8);
                const sphereMaterial = new THREE.MeshBasicMaterial({ color: streetLightColor });
                const lightSphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
                lightSphere.position.set(lightPositionX, 2.5, lightPositionZ);
                scene.add(streetLight);
                scene.add(lightSphere); // Add visual representation to locate the light source
                // streetLight.position.set(lightPositionX, 0.3, lightPositionZ);
                // scene.add(streetLight);
            }
        }

        const createRain = () => {
            const rainGeometry = new THREE.BufferGeometry();
            const rainCount = 5000;
            const positions = new Float32Array(rainCount * 3);

            // Create rain particles in a circular pattern around the train track
            for (let i = 0; i < rainCount; i++) {
                // Place rain particles along the train track (circular path)
                const angle = Math.random() * Math.PI * 2;
                const radius = 10; // Match the radius of the train track
                const x = radius * Math.cos(angle);
                const z = radius * Math.sin(angle);

                // Add some random offset to spread rain near buildings and along the track
                positions[i * 3] = x + (Math.random() - 0.5) * 5; // Offset for spreading around the track
                positions[i * 3 + 1] = Math.random() * 10 + 0.01; // Random height for falling rain
                positions[i * 3 + 2] = z + (Math.random() - 0.5) * 5; // Offset for spreading around buildings
            }

            rainGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
            const rainMaterial = new THREE.PointsMaterial({ color: 0xaaaaaa, size: 0.05 });
            const rain = new THREE.Points(rainGeometry, rainMaterial);

            scene.add(rain);
            rainParticles.push(rain);
        }

        const updateCameraPosition = () => {
            camera.position.x = 10.8 * Math.cos(angle);
            camera.position.z = 10.8 * Math.sin(angle);
            camera.position.y = 0.19;
            camera.lookAt(10 * Math.cos(angle + 0.1), 0.05, 10 * Math.sin(angle + 0.1));
            // Adjust the playback rate based on the current speed
            // const playbackRate = 0.5 + (currentSpeed / maxSpeed) * 1.5; // Scale between 0.5 and 2.0
            // engineSound.setPlaybackRate(playbackRate);
        };

        const  animateRain = () => {
            rainParticles.forEach(particle => {
                const positions = particle.geometry.attributes.position.array;
                for (let i = 0; i < positions.length; i += 3) {
                    positions[i + 1] -= 0.1; // Decrease the y-coordinate to make particles fall
                    if (positions[i + 1] < 0) {
                        positions[i + 1] = Math.random() * 10 + 5; // Reset height when it reaches the ground
                    }
                }
                particle.geometry.attributes.position.needsUpdate = true;
            });
        }

        const setWeatherMode = (mode) => {
            switch (mode) {
                case 'sunny':
                    scene.background = new THREE.Color(0xa0d3f8);
                    sunLight.intensity = 1;
                    ambientLight.intensity = 0.5;
                    renderer.shadowMap.enabled = true;
                    scene.fog = null;
                    scene.add(sunLight);
                    break;
                case 'foggy':
                    scene.background = new THREE.Color(0x999999);
                    scene.fog = new THREE.Fog(0x999999, 5, 20);
                    sunLight.intensity = 0.5;
                    ambientLight.intensity = 0.5;
                    renderer.shadowMap.enabled = false;
                    scene.add(sunLight);
                    break;
                case 'rainy':
                    scene.background = new THREE.Color(0x555555);
                    createRain();
                    sunLight.intensity = 0.3;
                    ambientLight.intensity = 0.3;
                    renderer.shadowMap.enabled = false;
                    scene.add(sunLight);
                    break;
                case 'night':
                    scene.background = new THREE.Color(0x010101);
                    sunLight.intensity = 0;
                    moonLight.intensity = 0.5;
                    directionalLight.intensity = 0.1;
                    createMoon();
                    createStreetLightsAlongTrack();
                    break;
                default:
                    console.warn('Unknown weather mode:', mode);
                    break;
            }
        }

        const onWindowResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        };

        const onDocumentKeyDown = (event) => {
            // Prevent the default behavior of the arrow keys (e.g., scrolling the page)
            if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
                console.log("On document key down");
                
                event.preventDefault();
            }
            if (event.key === 'ArrowUp') {
                    angle += cameraSpeed;  // Move camera left
                    keyPressed.up = true;
                    keyPressed.down = false;
                    playHorn(engineSound);
                    playHorn(hornSound); // Play the horn sound
            }
            if (event.key === 'ArrowDown') {
                    angle -= cameraSpeed;  // Move camera right
                    keyPressed.down = true;
                    keyPressed.up = false;
                    playHorn(hornSound); // Play the horn sound
            }
        };

        // const handleKeyDown = (event) => {
        //     if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
        //         console.log("prevent defualt");
                
        //         event.preventDefault();
        //     }
    
        //     if (event.key === 'ArrowUp') {
        //         keyPressedRef.current.up = true;
        //         keyPressedRef.current.down = false;
        //         angle += cameraSpeed;  // Move camera left
        //             keyPressed.up = true;
        //             keyPressed.down = false;
        //     }
        //     if (event.key === 'ArrowDown') {
        //         keyPressedRef.current.down = true;
        //         keyPressedRef.current.up = false;
        //         angle -= cameraSpeed;  // Move camera right
        //     }
        // };

        const onDocumentKeyUp = (event) => {
            if (event.key === 'ArrowUp') keyPressed.up = false;
            if (event.key === 'ArrowDown') keyPressed.down = false;
        };

        // Function to load audio
        const loadAudio = (audioFile, camera) => {
            const listener = new THREE.AudioListener();
            camera.add(listener);

            const sound = new THREE.Audio(listener);
            const audioLoader = new THREE.AudioLoader();
            
            audioLoader.load(audioFile, (buffer) => {
                sound.setBuffer(buffer);
                sound.setLoop(true);
                sound.setVolume(0.5);
            });

            return sound;
        };

        const playHorn = (hornSound) => {
            if (hornSound.isPlaying) {
                hornSound.stop(); // Stop if already playing to replay
            }
            hornSound.play();
        };

        

    return (
        <Container>
            <Content>
                <div ref={mountRef}  />;
            </Content>
        </Container>
    );
};

export default OutdoorExerciseDetail;
