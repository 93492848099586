// WeatherContext.js
import React, { createContext, useState } from 'react';

export const WeatherContext = createContext();

export const WeatherProvider = ({ children }) => {
    const [weatherMode, setWeatherMode] = useState('sunny'); // Default mode

    return (
        <WeatherContext.Provider value={{ weatherMode, setWeatherMode }}>
            {children}
        </WeatherContext.Provider>
    );
};
