// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import ExerciseDetail from './components/ExerciseDetail';
import OutdoorExerciseDetail from './components/OutdoorExerciseDetail';
import './App.css'; // Include CSS for styling the active link
import { WeatherProvider } from './contexts/WeatherContext';
import { SocketProvider } from './contexts/SocketContext';


function App() {
  return (
    <SocketProvider>
    <WeatherProvider>
    <Router>
      <div className="nav-container">
        <nav>
          <ul className="nav-list">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Login
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Dashboard
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/exercise/:exerciseId" element={<ExerciseDetail />} />
        <Route path="/outdoorexercise/:exerciseId" element={<OutdoorExerciseDetail />} />
      </Routes>
    </Router>
    </WeatherProvider>
    </SocketProvider>
  );
}

export default App;
