// src/Dashboard.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
// Example: Using NavLink from react-router-dom to set active class
import { NavLink } from 'react-router-dom';
import Instructors from './components/Instructors';
import Observers from './components/Observers';
import Recent from './components/Recent';
import Current from './components/Current';
import './App.css';

function Dashboard() {
  return (
    <div className="dashboard">
      <div className="sidebar">
        <ul>
          <li><NavLink to="instructors" activeClassName="active">Instructors</NavLink></li>
          <li><NavLink to="observers" activeClassName="active">Observers</NavLink></li>
          <li><NavLink to="recent" activeClassName="active">Recent</NavLink></li>
          <li><NavLink to="current" activeClassName="active">Current</NavLink></li>
        </ul>
      </div>
      <div className="content">
        <Routes>
          <Route path="instructors" element={<Instructors />} />
          <Route path="observers" element={<Observers />} />
          <Route path="recent" element={<Recent />} />
          <Route path="current" element={<Current />} />
        </Routes>
      </div>
    </div>
  );
}

export default Dashboard;
